// word docx support
import { Document, Packer, Paragraph, Table as TableDocx, TableCell as TableCellDocx, TableRow as TableRowDocx } from "docx"
import { parseHtmlFormsToJson, flattenData } from './htmlToJson'
import { downloadExport } from './downloadData'

export const docExporter = async (htmlData) => {
    const exporterData = parseHtmlFormsToJson(htmlData)
    
    const doc = new Document({
        creator: "CompraMED",
        description: "Export",
        title: "Export",
        sections: [],
    })

    // Iterate through each section
    for (const sectionData of exporterData) {
        // Create a new table for the section
        const table = new TableDocx({
            width: { size: "100%", type: "pct" },
            rows: sectionData.map(rowData => {
                // Create cells for each row
                const cells = rowData.map(cellData => {
                    const { label, value, type } = cellData
                    let content = " " // Default content
        
                    switch (type) {
                        case 'input':
                        case 'headerData':
                            content = `${label}: ${value}`
                            break
                        case 'showcase':
                        case 'gridData':
                        case 'chatdata':
                            content = `${label}: ${flattenData(value)}`
                            break
                        default:
                            break
                    }
                    
                    // Create and return a TableCell with the content
                    return new TableCellDocx({
                        children: [new Paragraph(content)]
                    })
                })
        
                // Check if there are cells to add before creating TableRowDocx
                if (cells.length > 0) {
                    return new TableRowDocx({
                        children: cells
                    })
                } else {
                    return null // Return null if no cells to add
                }
            }).filter(row => row !== null) // Filter out null rows
        })

        // Create a new section with the table
        const section = {
            properties: {},
            children: [table]
        }

        // Add the section to the document
        doc.addSection(section)
    }

    //console.log(exporterData)
    // convert to blob and return to user
    const docBlob = await Packer.toBlob(doc)
    downloadExport(docBlob, 'exported_data.docx')
}
