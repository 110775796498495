import { 
    Stack, 
    Heading, 
    Section
} from '@carbon/react';
import { useAuth } from '../../contexts/AuthContext';
import { UiShell } from '../../containers/Template'
import { DataCRUD } from '../../containers/DataCRUD'

// datacrud setup
import { publicEntitiesDataCRUD } from './publicEntitiesDataCRUD'

const PublicEntities = () => {
    const { user } = useAuth();
    const canAdd = user.permissions.includes('add_publicentity')
    const canChange = user.permissions.includes('change_publicentity')
    
    // DataCRUD callbacks
    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data created: ${result}`)
        }
    }

    return (
        <UiShell>
            <Stack gap={8}>
                <Section>
                    <Heading className="primary">Entes Principais</Heading>
                    <p>Entes Principais são responsáveis pelo contrato e empenho do orçamento entre suas entidades vinculadas.</p>
                </Section>
                <Section>
                    <DataCRUD 
                        title={'Entes Principais'} 
                        desc={'Todos os cadastros'} 
                                
                        {...publicEntitiesDataCRUD}
                        
                        canAdd={canAdd}
                        canChange={canChange}
                        onSave={onSaveData}
                        onCreate={onCreateData}
                    /> 
                </Section>
            </Stack>
        </UiShell>
    )
}

export default PublicEntities