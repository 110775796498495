

// api imports
import { getGroupsApi, getUsersApi, setUsersApi, getUserLogsApi } from '../../utils/api/accounts'


// DataManager form setup
const form = [
    [
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            key: 'first_name'
        },
        {
            label: 'Sobrenome',
            helper: '',
            type: 'text',
            key: 'last_name'
        },
        {
            label: 'Nivel de Acesso',
            helper: '',
            type: 'select',
            fetchApi: getGroupsApi,
            optionText: 'name',
            key: 'group'
        }
    ],
    [
        {
            label: 'ID',
            helper: '',
            type: 'text',
            key: 'id',
            disabled: true
        },
        {
            label: 'Email',
            helper: '',
            type: 'text',
            key: 'email'
        },
        {
            label: 'Status',
            helper: '',
            type: 'select',
            data: [
                {
                    name: 'Inativo',
                    id: false
                },
                {
                    name: 'Ativo',
                    id: true
                }
            ],
            default: true,
            optionText: 'name',
            key: 'is_active'
        }
    ],
    [
        {
            label: 'Vínculo Funcional',
            helper: '',
            type: 'text',
            key: 'staff_of',
            disabled: true
        },
        {
            label: 'Gestor',
            helper: '',
            type: 'text',
            key: 'staff_manager',
            disabled: true
        },
        {
        
            label: 'Cadastrado em',
            helper: '',
            type: 'text',
            key: 'created_at',
            disabled: true
        }
    ]/* ,
    [
        {
            label: 'Senha',
            helper: '',
            type: 'password',
            key: 'password1'
        },
        {
            label: 'Confirmacao de Senha',
            helper: '',
            type: 'password',
            key: 'password2'
        },
        {}
    ] */
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'Nome',
        key: 'name'
    },
    {
        header: 'Vínculo Funcional',
        key: 'staff_of'
    },
    {
        header: 'Nível de Acesso',
        key: 'group_code'
    },
    {
        header: 'Gestor',
        key: 'status_code'
    },
    {
        header: 'Acessos',
        key: 'access_count'
    },
    {
        header: 'Status',
        key: 'user_status'
    }
]

const dataGridSearchBy = ['first_name', 'last_name', 'staff_of', 'group', 'status', 'access', 'user_status'] //dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)

// the main DataCRUD setup
export const operatorsDataCRUD = {
    headers: dataGridHeader, 
    searchBy: dataGridSearchBy,
    form: form,
    formTitleKey: 'first_name',
    getApi: getUsersApi,
    setApi: setUsersApi
}
