import { useState } from "react";
import { 
    Select,
    SelectItem,
    Modal,
} from '@carbon/react';

// exporter
//const exportFormats = ['PRINT', 'DOCX', 'PDF', 'XLSX', 'CSV', 'TXT', 'HTML']
const exportFormats = ['PRINT', 'DOCX', 'PDF', 'HTML']

export const ExporterBox = ({ 
    open,
    onRequestClose,
    onExportRequest = null 
}) => {
    const [ exportFormat, setExportFormat ] = useState(null)

    const exportData = () => {
        onExportRequest?.(exportFormat)
    }

    return (
        <>
            {open &&
                <Modal 
                    open={open}
                    modalHeading="Exportar Lista"
                    primaryButtonText="Exportar" 
                    secondaryButtonText="Cancelar" 
                    onRequestSubmit={exportData} 
                    onRequestClose={onRequestClose}
                >
                    <Select
                        id="exporter-format-select"
                        name="exporter-format-select"
                        labelText="Selecione o formato de exportação:"
                        onChange={e => setExportFormat(e.target.value)}
                        value={exportFormat}
                    >
                        <SelectItem key="011" value="" text="Selecione um formato" />
                        {exportFormats?.map((e, idx) => 
                            <SelectItem key={idx} value={e} text={e === 'PRINT' ? 'IMPRIMIR' : e} />
                        )}
                    </Select>                  
                </Modal>
            }
        </>
    )
}