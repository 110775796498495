import { useEffect, useState, useRef, useCallback } from "react"
import moment from 'moment'
import { 
    TextInput, 
    TextArea,
    Form, 
    Heading, 
    Section, 
    FlexGrid, 
    Row, 
    Column,
    Select,
    SelectItem,
    DatePicker,
    DatePickerInput,
    Button,
    Tile,
} from '@carbon/react'
import { TrashCan, Save, Edit, Close, SendAlt } from '@carbon/icons-react';

import { OpMode } from './DataCRUD'
import { MediaManager } from '../components/MediaManager'
import MaskedTextInput from '../components/MaskedTextInput'

// utils
import { flattenObject, deepEqual } from '../utils/objects'
import { checkActionPermissionContext } from '../utils/permissions'
import { unmaskValue } from '../utils/inputMasks'
import { getAddressByCep } from '../utils/api/location'

// this is used to convert formbuilder form data into backend expected format for patches updates or posts for creation
const preFormatFields = (form, data, originalData) => {
    const resultObject = {}
    for (const innerArray of form) {
        for (const innerObject of innerArray) {
            const { key, type, disabled, formatCallback } = innerObject
            const originalValue = originalData ? originalData[key] : null
            // remove disabled fields from form
            if (!disabled) {
                let fieldData = data?.hasOwnProperty(key) ? data[key] : originalValue
                // pre format templates
                if (type === 'date' && fieldData && fieldData !== originalValue) {
                    fieldData = moment(fieldData, "DD/MM/YYYY").format("YYYY-MM-DD")
                }
                if ((type === 'money' || type === 'percentage') && fieldData !== originalValue) {
                    fieldData = unmaskValue(fieldData, type)
                }
                // if we have a formatCallback lets format!
                if (formatCallback && fieldData) {
                    fieldData = formatCallback(fieldData)
                }
                if (fieldData !== null) {
                    if (originalValue === fieldData) continue
                    // attachments handles different from other fields since its for files
                    if (key === 'attachments') {
                        // to keep track of what to keep and what to delete, sent empty objects with ids to keep
                        // otherwise the new attachment will be fully added
                        const attachmentsToSent = fieldData.map((fieldObject) => {
                            if (fieldObject.hasOwnProperty('id'))
                                return { id: fieldObject.id }
                            else
                                return fieldObject
                        })
                        // in case all attachments were requested to be deleted, we sent one object with id: 0 to force all deletion
                        resultObject['attachments'] = attachmentsToSent.length > 0 ? attachmentsToSent : [{id: 0}];
                    } else {
                        resultObject[key] = fieldData
                    }
                } 
            }
        }
    }
    if (data?.hasOwnProperty('id') && data.id !== null) {
        resultObject['id'] = data.id
    }
    return resultObject
}

// 'XLS', 'CSV', 'TXT'
export const generateFormTabularHeaderJson = (form, formDataExtension) => {
    const [ resultObject ] = generateForm(form, formDataExtension)
    const managerData = resultObject
    
    // title, how? without client rendering?
    //managerData.hasOwnProperty(formTitleKey) ? managerData[formTitleKey] : formTitleFunc ? formTitleFunc(managerData) : formTitle
    const header = []
    
    /* form.forEach(row => {
        row.forEach(col => {
            header.push(col.label)
        })
    })
    result.push(header) */

    form.map((rowFields, idx) => {
        return rowFields.filter(e => !e.hasOwnProperty('context') || Object.entries(e.context).every(([key, value]) => {
                const dataValue = managerData?.[key];
                return Array.isArray(value) ? 
                    dataValue !== undefined && value.includes(dataValue) :
                    dataValue !== undefined && dataValue === value;
            })
        ).forEach((field, idx) => {
            header.push(field.label)
        })
    })

    return header
}

// parses data from form and fill up with data for form building
const generateForm = (form, formDataExtension) => {
    // Initialize an empty object to store the result
    const resultObject = {}
    let fetchedDataObject = {}
    // for form template data layout instead of receiving from api
    let dataObject = {}
    // Iterate through the nested arrays
    for (const innerArray of form) {
        // Iterate through the inner objects
        for (const innerObject of innerArray) {
            // Extract the key from the inner object
            const { key, fetchApi, fetchApiParam, data, type } = innerObject;
            // Create a new key in the result object if it doesn't exist or not disabled
            if (!(key in resultObject)) {
                resultObject[key] = formDataExtension?.hasOwnProperty(key) ? formDataExtension[key] : null
            }

            // any associative form to query formDataExtension for?
            if (fetchApi) {
                fetchedDataObject[key] = { fetchApi: fetchApi, fetchApiParam: fetchApiParam }
            }

            // instead of fetching data use the layout provided in form
            if (data) {
                dataObject = { ...dataObject, [key]: data }
            }

        }
    }

    if (formDataExtension?.hasOwnProperty('id')) {
        resultObject['id'] = formDataExtension.id
    }

    return [ resultObject, fetchedDataObject, dataObject ]
}

export const FormBuilder = ({ 
    form=[], 
    formTitleKey="", 
    formTitle="", 
    formTitleFunc=null,
    formData=null, 
    formActions=null, 
    formAutoRefresh=null,
    setApi, 
    setApiParam=null, 
    onSave=null, 
    onCreate=null, 
    onCancel=null, 
    onFormDisplay=null,
    apiFlatData=false,
    getApi=null,
    getApiParam=null,

    canAdd=true, 
    canChange=true,
    canDelete=false,
    canCallActions=true,

    exportRender=false,

    filter=0,
    onAction=null,
    permissions=[]

}) => { 
    // datamanager
    const [ editMode, setEditMode ] = useState(OpMode.standby)
    // to keep fetch handling data
    const [ fetchAssociativeData, setFetchAssociativeData ] = useState({})
    // to keep data fetched from fetchApi 
    const [ fetchedData, setFetchedData ] = useState({})
    // to request a reload api, mostly used when associative calls are needed
    const [ fetchRequest, setFetchRequest ] = useState([])
    // the form data being edited to be saved
    const [ managerData, setManagerData ] = useState(null)
    // validation for error description
    const [ validationErrors, setValidationErrors ] = useState([])
    // for auto refresh feature, safe cleanup
    const intervalIDRef = useRef(null)
    const scrollRef = useRef(null)
    const prevFetchRequest = useRef(-1)


    // for chatInput type extension
    const [ chatInputValue, setChatInputValue ] = useState("")

    // form changer watcher
    const handleChange = (e) => {
        e.preventDefault()

        const { name, value } = e.target;
        let managerValue = value
        
        // dinamicly handle the form data to be used on setAPI
        setManagerData({ ...managerData, [name]: managerValue });

        // cep autocomplete?
        if (name === 'cep' && managerValue.length === 9 && managerData[name] !== managerValue) {
            console.log("querying address by cep...")

            getAddressByCep(managerValue.replace('-','')).then(address => {
                if (address !== null) {
                    // endereco = logradouro
                    // cidade = localidade
                    // uf = uf
                    // complemento = complemento
                    // bairro, ibge: leave it for now
    
                    const autoCompleteAddress = {
                        cep: managerValue,
                        endereco: address?.logradouro,
                        complemento: address?.complemento,
                        uf: address?.uf_id,
                        cidade: address?.city_id
                    }
    
                    setManagerData({ ...managerData, ...autoCompleteAddress })
                    // force city to download all avaliable ones for the current uf
                    setFetchRequest(['cidade'])
                }
            })

        }
        
        // is this a associative field that needs to be updated based on a parent relationship?
        for (let key in fetchAssociativeData) {
            if (fetchAssociativeData[key].fetchApiParam === name) {
                setFetchRequest([key])
            }
        }
    }

    const handleChangeDate = (name, value) => {
        //const inputDate = new Date(value);
        //console.log(value[0])
        if (value.length === 0) return
        
        // dinamicly handle the form data to be used on setAPI
        setManagerData({ ...managerData, [name]: value[0] });
    }

    const handleSubmit = async (e, updatedManagerData=null) => {
        e.preventDefault()

        // handle any data transform and remove the disabled fields from form
        const request = preFormatFields(form, updatedManagerData ?? managerData, formData)
        
        if (setApiParam) {
            Object.assign(request, setApiParam)
        }
        let response = await setApi(request)

        let newFormData = null
        // patch > update
        if (response.status === 200) {
            if (apiFlatData)
                response.data = flattenObject(response?.data)
            onSave?.(response.data)
            // get back to view mode?
            //setSelectedData(response.data)
            setEditMode(OpMode.read)
            setValidationErrors({})
            // update any non editable info on form
            newFormData = generateData(form, response.data)
            //setManagerData(newFormData)
        // post > create
        } else if (response.status === 201) {
            if (apiFlatData)
                response.data = flattenObject(response?.data)
            onCreate?.(response.data)
            // get back to view mode?
            //setSelectedData(response.data)
            setEditMode(OpMode.read)
            setValidationErrors({})
            // update any non editable info on form
            //newFormData = generateData(form, response.data)
            //setManagerData(newFormData)
        // validation errors?
        } else if (response.status === 400) {
            console.log(response.data)
            setValidationErrors(response.data)
        }
        //if (onFormDisplay && newFormData)
        //    onFormDisplay(newFormData)
    }

    // button cancel
    const onCancelAction = () => {
        let newFormData = null
        if (editMode === OpMode.create) {
            // set null and hide our self
            setManagerData(null)
            setEditMode(OpMode.cancel)
        } else if (editMode === OpMode.edit) {
            // revert origianl data
            newFormData = generateData(form, formData)
            setManagerData(newFormData)
            setEditMode(OpMode.read)
        }
        //if (onFormDisplay)
        //    onFormDisplay(newFormData)
        setValidationErrors({})
        onCancel?.(editMode)
    }

    //
    // multimedia handling via attachements key
    //
    const onMediaDelete = (attachmentId, type) => {
        // check if we are in edit or create mode
        if (editMode === OpMode.read) return
        // check if attachmentId exists in any dataManager['attachments'] object as parameter, if so, remove it! and update
        // check against url param because it will be his attachmentId
        const updatedAttachments = managerData.attachments.filter(
            (attachment) => { 
                if (attachment?.id) { // medias uploaded
                    return attachment.id !== attachmentId && attachment[type] !== attachmentId 
                } else { // medias to be uploaded
                    return attachment[type]?.name ? attachment[type].name !== attachmentId : attachment[type] !== attachmentId
                }
            }
        )
        // Update managerData by adding the new object to the attachments array
        setManagerData({
            ...managerData,
            attachments: updatedAttachments
        })
    }

    const handleChangeFile = (mediaFileObjects) => {
        setManagerData({
            ...managerData,
            attachments: [...(managerData.attachments || []), ...(mediaFileObjects || [])]
        })
    }
    
    // generate the setApi expected formData format based on form props and data input
    const generateData = (formTemplate, formDataExtension=null) => {
        const [ resultObject, fetchedDataObject, dataObject ] = generateForm(formTemplate, formDataExtension)

        if (Object.keys(fetchedDataObject).length > 0) {
            setFetchAssociativeData(fetchedDataObject)
        }

        // any data to add via data property?
        if (Object.keys(dataObject).length > 0) {
            setFetchedData({ ...fetchedData, ...dataObject })
        }
        
        // request a fetch api
        setFetchRequest(Object.keys(fetchedDataObject))

        return resultObject
    }

    // fetch any associative data request on form
    useEffect(() => {
        //if (editMode === OpMode.read) return
        async function fetchAssociationData() {
            let fetchedDataObject = fetchedData
            let procFetchRequest = []
            if (fetchRequest.length === 0) return
            // Iterate through the nested arrays
            for (const request of fetchRequest) {
                // force triger discard
                if (request === '__force__reload__') continue
                // we dont want to fetch anydata not global '_' on read mode...
                if (editMode === OpMode.read && request !== '_') continue
                // keep track of wich ones we had processed so far
                procFetchRequest.push(request)
                // Extract the key from the inner object
                const { fetchApi, fetchApiParam } = fetchAssociativeData[request]
                const response = managerData?.hasOwnProperty(fetchApiParam) ? await fetchApi(managerData[fetchApiParam]) : await fetchApi()
                if (response) {
                    if (request === '_') {
                        //const procFormData = formDataFlatData ? flattenObject(response) : response
                        //const formDataGenerated = generateData(form, { ...managerData, ...procFormData })
                        const procFormData = flattenObject(response)
                        const formDataGenerated = generateData(form, { ...managerData, ...procFormData })
                        setManagerData(formDataGenerated)
                    } else {
                        fetchedDataObject = { ...fetchedDataObject, [request]: response }
                    }
                }
            }
            // reset request
            setFetchRequest(fetchRequest.filter(e => !procFetchRequest.includes(e)))
            setFetchedData(fetchedDataObject)
        }
        // Skip the first render
        if (prevFetchRequest.current !== -1 && (!deepEqual(prevFetchRequest.current, fetchRequest))) {
            fetchAssociationData()
        }
        prevFetchRequest.current = fetchRequest;
    }, [fetchRequest])

    useEffect(() => {
        // reset form
        let newFormData = null
        if (editMode === OpMode.create) {
            if (formData) {
                newFormData = generateData(form, formData)
                setManagerData(newFormData)
            } else {
                newFormData = generateData(form)
                setManagerData(newFormData)
            }
            setValidationErrors({})
            //setSelectedData(null)
            //if (onFormDisplay)
            //    onFormDisplay(newFormData)
        } 
        /* if (editMode === OpMode.edit || editMode === OpMode.create) {
            // force fetchRequest
            setFetchRequest([ ...fetchRequest ])
        } */
        if (editMode === OpMode.edit) {
            // force fetchRequest
            setFetchRequest([ ...fetchRequest, '__force__reload__' ])
        }
        // reset form
        setValidationErrors({})
        // external change form state display
        onFormDisplay?.(newFormData !== null ? newFormData : managerData, editMode)
    }, [editMode])

    useEffect(() => {
        //let mergeData = {}
        if (Object.keys(formData).length === 0) {
            setEditMode(OpMode.create)
            return
        }
        //const procFormData = formDataFlatData ? flattenObject(formData) : formData
        const formDataGenerated = generateData(form, { ...managerData, ...formData })
        setManagerData(formDataGenerated)
        // avoid edit request going to read again
        if (editMode === OpMode.edit) 
            return
        if (formDataGenerated?.id) {
            setEditMode(OpMode.read)
        } else {
            setEditMode(OpMode.create)
        }
    }, [formData])

    // auto refresh form support
    const chatInputHandle = (e, param) => {
        // update managerData
        handleSubmit(e, { ...managerData, [param]: chatInputValue })
        // clear managerdata field after!
        setChatInputValue("")
    }

    const refreshData = async (dataId) => {
        let response
        if (getApiParam) {
            response = await getApi?.(getApiParam)
        } else {
            response = await getApi?.({ id: dataId })
        }

        if (apiFlatData)
            response.data = flattenObject(response)

        setManagerData(response)

        // scroll text chat box to bottom at new messages
        scrollConversationToBottom()
    }

    const scrollConversationToBottom = () => {
        // we can compare managerData states to conversatoin field for changes to scroll only on change old to current
        // scroll to bottom
        if (scrollRef.current) {
            const container = scrollRef.current;
            container.scrollTop = container.scrollHeight;
        }
        /* const container = scrollRef.current;
        const isScrolledToBottom = container.scrollHeight - container.clientHeight <= container.scrollTop + 1;

        if (isScrolledToBottom) {
            container.scrollTop = container.scrollHeight;
        } */
    }

    // auto refresh feature
    useEffect(() => {
        // clear any timer before set another one
        if (intervalIDRef.current !== null) {
            stopTimer()
        }
            
        if (formAutoRefresh && managerData?.id && editMode === OpMode.read) {
            startTimer(formAutoRefresh, managerData.id)
            scrollConversationToBottom()
        }
    }, [managerData])

    useEffect(() => {
        return () => clearInterval(intervalIDRef.current)
    }, [])

    const startTimer = useCallback((seconds, dataId) => {
        intervalIDRef.current = setInterval(() => {
            refreshData(dataId)
        }, seconds * 1000)
    }, []);

    const stopTimer = useCallback(() => {
        clearInterval(intervalIDRef.current);
        intervalIDRef.current = null;
    }, []);
    
    return (
        <>
            {managerData &&
                <Section level={3}>
                    <FlexGrid style={{ paddingInline: 0, marginBottom: '24px' }} fullWidth>
                        <Row>
                            <Column style={{ flex: '0', whiteSpace: 'nowrap' }}>
                                <Heading className="primary">{editMode === OpMode.create ? formTitleFunc ? formTitleFunc(managerData) : 'Adicionar Registro' : managerData.hasOwnProperty(formTitleKey) ? managerData[formTitleKey] : formTitleFunc ? formTitleFunc(managerData) : formTitle }</Heading>
                            </Column>
                            {(exportRender === false) &&
                                <Column style={{ flex: '1', textAlign: 'right' }}>
                                    {((formActions && editMode === OpMode.read) && canCallActions)&&
                                        Object.entries(formActions)
                                            .filter(([action, actionData]) => {
                                                // ignore our default internal actions here add, edit and delete
                                                if (action === 'add' || action === 'edit' || action === 'delete')
                                                    return false
                                                return checkActionPermissionContext(actionData, permissions, managerData)
                                            })
                                            .map(([actionName, actionData]) => (
                                                <Button
                                                    disabled={actionData.disabled ?? false}
                                                    onClick={() => onAction?.(actionName, managerData)}
                                                    style={{ marginRight: '8px' }}
                                                    renderIcon={actionData.icon}
                                                    iconDescription=""
                                                    kind="ghost"
                                                >
                                                    {actionData.label}
                                                </Button>
                                            ))
                                    }
                                    {(editMode === OpMode.read && canChange) &&
                                        <>
                                            {formActions?.edit ? 
                                                    (checkActionPermissionContext(formActions.edit, permissions, managerData) &&
                                                        <Button onClick={() => setEditMode(OpMode.edit)} style={{ marginRight: '8px' }} renderIcon={formActions?.edit?.icon ?? Edit} iconDescription={formActions?.edit?.label ?? "Editar"} kind="tertiary">
                                                            {formActions?.edit?.label ?? "Editar"}
                                                        </Button>
                                                    )
                                                :
                                                    <Button onClick={() => setEditMode(OpMode.edit)} style={{ marginRight: '8px' }} renderIcon={Edit} iconDescription="Editar" kind="tertiary">
                                                        {'Editar'}
                                                    </Button>
                                            }
                                            {canDelete &&
                                                <Button onClick={() => console.log("delete me!")} style={{ marginRight: '8px' }} renderIcon={TrashCan} iconDescription="Apagar" kind="danger">
                                                    {'Apagar'}
                                                </Button>
                                            }
                                        </>
                                    }
                                    {(editMode === OpMode.edit || editMode === OpMode.create) &&
                                        <>
                                            <Button onClick={onCancelAction} style={{ marginRight: '8px' }} renderIcon={formActions?.cancel?.icon ?? Close} iconDescription="Cancelar" kind="tertiary">
                                                {'Cancelar Edição'}
                                            </Button>
                                            {(formActions?.save?.hide !== true) &&
                                                <Button onClick={handleSubmit} renderIcon={Save} iconDescription="Salvar" disabled={editMode !== OpMode.edit && editMode !== OpMode.create} >
                                                    {'Salvar'}
                                                </Button>
                                            }
                                        </>
                                    }
                                </Column>
                            }
                        </Row>
                    </FlexGrid>
                    <Form aria-label="Form" className="formBuilderData">
                        <FlexGrid style={{ paddingInline: 0 }} fullWidth>
                            {form.map((rowFileds, idx) => 
                                <Row id={idx} key={idx} className="formBuilderDataRow">
                                    {rowFileds.filter(e => !e.hasOwnProperty('context') ? true :
                                        Object.entries(e.context).every(([key, value]) => {
                                            const dataValue = managerData?.[key]
                                            if (Array.isArray(value)) {
                                                // Check if the managerData value is in the array
                                                return dataValue !== undefined && value.includes(dataValue);
                                            } else {
                                                // Check if the managerData value matches the single value
                                                return dataValue !== undefined && dataValue === value;
                                            }
                                        })
                                    ).map((field, idx) => {
                                        // generic form validation and value management from managerData arguments
                                        const isInvalid = validationErrors.hasOwnProperty(field.key)
                                        const invalidText = validationErrors.hasOwnProperty(field.key) ? validationErrors[field.key][0] : ''
                                        const arrValue = field.hasOwnProperty('idx') && Array.isArray(managerData[field.key]) && field.idx < managerData[field.key].length
                                        const value = managerData.hasOwnProperty(field.key) && managerData[field.key] !== null ? 
                                            arrValue ? 
                                                managerData[field.key][field.idx] : managerData[field.key]
                                            : field?.default ?? ''
                                        //managerData.hasOwnProperty(field.key) && managerData[field.key] !== null ? managerData[field.key] : field?.default ?? ''
                                        return (
                                            <Column key={idx} style={{ marginBottom: '32px' }} className="formBuilderDataColumn">
                                                {(field.type === 'cnpj' || field.type === 'cep' || field.type === 'phone' || field.type === 'money'  || field.type === 'percentage') &&
                                                    <>
                                                        <MaskedTextInput                                     
                                                            invalidText={invalidText}
                                                            invalid={isInvalid} 
                                                            onChange={e => handleChange(e, field.type)} 
                                                            onKeyUp={e => handleChange(e, field.type)} 
                                                            disabled={editMode === OpMode.read || field.disabled} 
                                                            value={value}
                                                            id={field.key} 
                                                            name={field.key} 
                                                            labelText={field.label} 
                                                            helperText={field.helper} 
                                                            maskType={field.type}
                                                        />
                                                    </>
                                                }
                                                {field.type === 'component' && 
                                                    <field.component  
                                                        managerData={managerData}   
                                                        setManagerData={setManagerData}
                                                        editMode={editMode}
                                                        filter={filter}
                                                        onAction={onAction}
                                                        exportMode={exportRender}
                                                    />
                                                }
                                                {(field.type === 'text' || field.type === 'number' || field.type === 'password') &&
                                                    <TextInput 
                                                        /* autoComplete="true"  */
                                                        type={field.type}
                                                        invalidText={invalidText}
                                                        invalid={isInvalid} 
                                                        onChange={handleChange} 
                                                        disabled={editMode === OpMode.read || field.disabled} 
                                                        value={value}
                                                        id={field.key} 
                                                        name={field.key} 
                                                        labelText={field.label} 
                                                        helperText={field.helper} 
                                                    />
                                                }
                                                {field.type === 'header' &&
                                                    <Section level={4}>
                                                        <Heading className="primary formDataHeaderLabel">{field.label}</Heading>
                                                    </Section>
                                                }
                                                {field.type === 'longText' &&
                                                    <TextArea
                                                        /* autoComplete="true"  */
                                                        invalidText={invalidText}
                                                        invalid={isInvalid} 
                                                        onChange={handleChange} 
                                                        disabled={editMode === OpMode.read || field.disabled} 
                                                        /* value={managerData.hasOwnProperty(field.key) ? managerData[field.key] : ''}  */
                                                        value={value}
                                                        id={field.key} 
                                                        name={field.key} 
                                                        labelText={field.label} 
                                                        helperText={field.helper} 
                                                    />                                                
                                                }
                                                {field.type === 'conversation' &&
                                                    <div style={exportRender ? {} : { height: '250px', overflowY: 'auto' }} ref={scrollRef}>
                                                        <div style={{ height: 'auto' }}>
                                                            <Tile 
                                                                id="conversation"
                                                                name="conversation"
                                                                labelText={field.label} 
                                                                helperText={field.helper} 
                                                                className="formBuilderDataChatData"
                                                            >
                                                                {value?.map?.((e,index) => 
                                                                    <p style={{ marginTop: '12px', marginBottom: '12px' }} key={index}>
                                                                        <strong>{e.sender === null ? 'Sistema' : `${e.sender.first_name} ${e.sender.last_name}`}</strong> {`(${e.created_at}): ${e.message}`}
                                                                    </p>
                                                                )}
                                                            </Tile>   
                                                        </div>
                                                    </div>                                             
                                                }
                                                {(field.type === 'chatInput' && !exportRender) &&
                                                    <Section style={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextInput 
                                                            type="text"
                                                            onChange={e => setChatInputValue(e.target.value)} 
                                                            onKeyDown={(e) => e.keyCode === 13 ? chatInputHandle(e, field.key) : undefined}
                                                            value={chatInputValue}
                                                            id={field.key} 
                                                            name={field.key} 
                                                            labelText={field.label}
                                                            helperText={field.helper} 
                                                            invalidText={invalidText}
                                                            invalid={isInvalid} 
                                                            placeholder={ editMode === OpMode.create ? "Para iniciar um atendimento, selecione um assunto acima e, após isso, envie sua mensagem. Um protocolo será gerado automaticamente." : "" }
                                                        />
                                                        <Button
                                                            disabled={false}
                                                            onClick={(e) => chatInputHandle(e, field.key)}
                                                            style={{ marginLeft: '8px' }}
                                                            renderIcon={SendAlt}
                                                            iconDescription=""
                                                            kind="primary"
                                                        >
                                                            Enviar
                                                        </Button>
                                                    </Section>                                               
                                                }
                                                {field.type === 'select' && (
                                                    (editMode === OpMode.create || editMode === OpMode.edit) ? (
                                                        <Select
                                                            invalidText={invalidText}
                                                            invalid={isInvalid} 
                                                            disabled={editMode === OpMode.read || field.disabled} 
                                                            id={field.key} 
                                                            name={field.key} 
                                                            labelText={field.label} 
                                                            helperText={field.helper} 
                                                            onChange={handleChange}
                                                            value={value}
                                                        >
                                                            <SelectItem value="" text="Escolha uma opcao" />
                                                            {fetchedData.hasOwnProperty(field.key) && fetchedData[field.key].map(option => (
                                                                <SelectItem 
                                                                    selected={value === option.id} 
                                                                    key={option.id} 
                                                                    value={option.id} 
                                                                    text={option[field.optionText]} 
                                                                />
                                                            ))}
                                                        </Select> 
                                                    ) : (
                                                        <TextInput 
                                                            type={'text'}
                                                            disabled={true} 
                                                            value={formData?.[`${field.key}_code`] ?? field?.data?.find(e => e.id === value)?.name}
                                                            id={field.key} 
                                                            name={field.key} 
                                                            labelText={field.label} 
                                                            helperText={field.helper} 
                                                        />
                                                    )
                                                )}
                                                {field.type === 'showCase' &&
                                                    Object.keys(value).map((key, idx) => (
                                                        <Row id={idx} key={idx} style={{ marginBottom: '30px' }} className="formBuilderDataShowcase">
                                                            
                                                            <Column key={idx}>
                                                                <TextInput 
                                                                    type="text"
                                                                    disabled={true} 
                                                                    value={key}
                                                                    id={key} 
                                                                    name={key} 
                                                                    labelText="Campo"
                                                                />
                                                            </Column>

                                                            {value[key].map((item, index) => (
                                                                <Column key={`${idx}-${index}`}>
                                                                    <TextInput 
                                                                        key={index}
                                                                        type="text"
                                                                        disabled={true} 
                                                                        value={item}
                                                                        id={key} 
                                                                        name={key} 
                                                                        labelText={index === 0 ? 'Conteúdo Inicial: ' : 'Conteúdo Final: '}
                                                                    />
                                                                </Column>
                                                            ))}
                                                        </Row>
                                                    ))
                                                }
                                                {(field.type === 'date' || field.type === 'datetime') &&
                                                    ((editMode === OpMode.create || editMode === OpMode.edit) ? (
                                                        <DatePicker
                                                            datePickerType="single"
                                                            locale="pt"
                                                            value={value} 
                                                            placeholder={"dd/mm/aaaa"}
                                                            dateFormat={"d/m/Y"}
                                                            onChange={(e) => handleChangeDate(field.key, e)}
                                                            onClose={function noRefCheck(){}}
                                                            onOpen={function noRefCheck(){}}
                                                        >
                                                            <DatePickerInput
                                                                id={field.key} 
                                                                name={field.key} 
                                                                labelText={field.label} 
                                                                disabled={editMode === OpMode.read || field.disabled} 
                                                                invalidText={invalidText}
                                                                invalid={isInvalid} 
                                                            />
                                                        </DatePicker>
                                                    ) : (
                                                        <TextInput 
                                                            type={'text'}
                                                            disabled={true} 
                                                            value={value}
                                                            id={field.key} 
                                                            name={field.key} 
                                                            labelText={field.label} 
                                                            helperText={field.helper} 
                                                        />
                                                    ))
                                                }    
                                                {(field.type === 'multimedia' && managerData.hasOwnProperty(field.key)) &&
                                                    <Section>
                                                        <Heading className="primary formDataHeaderLabel" level={4}>{field.label}</Heading>
                                                        <Row>
                                                        {field.medias.map((media, idx) => (
                                                                <Column key={idx}>
                                                                    <div style={{ marginBottom: '17px'}}>
                                                                        <MediaManager
                                                                            label={media.label}
                                                                            helper={media.helper}
                                                                            type={media.key}
                                                                            disabled={editMode === OpMode.read || field.disabled}
                                                                            onAdd={handleChangeFile}
                                                                            onDelete={onMediaDelete}
                                                                            showManager={editMode === OpMode.edit || editMode === OpMode.create}
                                                                            medias={managerData[field.key]}
                                                                            readMode={editMode === OpMode.read}
                                                                        />                                                            
                                                                    </div>
                                                                </Column>
                                                            ))}
                                                        </Row>
                                                    </Section>
                                                }                                        
                                            </Column>
                                        )}
                                    )}
                                </Row>
                            )}
                        </FlexGrid>
                    </Form>
                </Section>
            }
        </>
    )
}