import axios from "axios"
import carboneSDK from 'carbone-sdk-js'
import { downloadExport } from '../exporter/downloadData'
import { printExporter } from '../exporter/printer'

export const getLastReportApi = async () => {
  const response = await axios.get('/reports/', { withCredentials: true })
  return response.status === 200 ? response.data : null
}

export const setReportLogApi = async (type) => {
    return await axios.post('/reports/', { exported_type: type }, { withCredentials: true })
}

// carbona reports generation
// SDK constructor, the access token have to be passed as an argument to carboneRenderSDK
const CARBONA_API = 'https://reports.nasher.io'
const REPORTS_REPO = window.location.hostname !== 'localhost' ? '../report-templates/' : 'http://localhost:3000/report-templates/'
const FALLBACK_TEMPLATE_EXTENSION = '.html'
const carboneService = carboneSDK()
carboneService.setApiUrl(CARBONA_API)

/* carboneService.setApiHeaders({
    "carbone-template-delete-after": "86400",
    "carbone-webhook-url": "https://..."
  }); */
//console.log(Object.getOwnPropertyNames(carboneService))

const getFileFromURI = async (uri, extensionType) => {
  const response = await axios.get(uri, {
    responseType: 'arraybuffer'
  })
  
  // Check if the response is not 404
  if (response.status !== 404) {
    const blob = new Blob([response.data], {
      type: response.headers['content-type'] // Use the Content-Type from the response headers
    })
    return { template: blob, fallback: false }
  }
  
  // Fallback to .html extension if template extension not found
  const htmlUri = uri.replace(`.${extensionType}`, FALLBACK_TEMPLATE_EXTENSION)
  console.log(`Fallback to .${FALLBACK_TEMPLATE_EXTENSION} template: ${htmlUri}`)
  const fallback_response = await axios.get(htmlUri, {
    responseType: 'arraybuffer'
  })

  // Check if the response is not 404
  if (fallback_response.status !== 404) {
    const blob = new Blob([fallback_response.data], {
      type: fallback_response.headers['content-type'] // Use the Content-Type from the response headers
    })
    return { template: blob, fallback: true }
  }

  const fallbackBlob = new Blob(['<html><body><h1>Report template not found!</h1></body></html>'], { type: 'text/html' })
  return { template: fallbackBlob, fallback: true }
}

export const generateReportApi = async (reportName, exportType, data) => {
  const extension = exportType.toLowerCase()
  const uri = REPORTS_REPO + reportName + '.' + extension
  await getFileFromURI(uri, extension).then(({ template, fallback }) => {
    // fallback or print needs to change extension
    if (fallback && extension !== 'print') {
      data["convertTo"] = extension
    }
    carboneService.render(template, data).then(async ({ content, name }) => {
      // name == report name as a String
      // content == report content as a Blob, it can be used to download the file
      // handle print statement
      if (extension === 'print') {
        const htmlText = await new Response(content).text()
        printExporter(htmlText, false)
      } else {
        downloadExport(content, name)
      }
    })
  }).catch((error) => {
    console.error('Error fetching file:', error)
  })
}