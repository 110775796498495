import { useState } from "react"
import { 
    Stack, 
    Heading, 
    Section, 
} from '@carbon/react';
import { useAuth } from '../../contexts/AuthContext'
import { UiShell } from '../../containers/Template'
import { DataCRUD, OpMode } from '../../containers/DataCRUD'
import { UserLogs } from '../../containers/UserLogs'
import EntityNavigation from '../../components/EntityNavigation'

// datacrud setup
import { operatorsDataCRUD } from './operatorsDataCRUD'

const Operators = () => {
    const [ selectedEntity, setSelectedEntity ] = useState(null)
    const [ entitiesNavPath, setEntitiesNavPath ] = useState('')
    const [ userData, setUserData ] = useState(null)
    const { user } = useAuth();
    const canAdd = user.permissions.includes('add_entity_user') && selectedEntity
    const canChange = user.permissions.includes('change_entity_user')

    // DataCRUD callbacks
    const onFormDisplay = async (data, formEditMode) => {
        if ((formEditMode === OpMode.standby || formEditMode === OpMode.cancel || formEditMode === OpMode.create) && data === null) {
            setUserData(null)
        } else if (data?.id && (formEditMode === OpMode.edit || formEditMode === OpMode.read)) {
            setUserData({ ...data, name: `${data.first_name} ${data.last_name}`})
        }
    }

    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data created: ${result}`)
        }
    }

    const onNavChange = (entityId, place) => {
        setSelectedEntity(entityId)
        setEntitiesNavPath(place)
    }

    return (
        <UiShell>
            <Stack gap={8}>
                <Section>
                    <Stack gap={4}>
                        <Heading className="primary">Operadores Autorizados</Heading>
                        <p>Usuários autorizados a cadastrar produtos, aprovar e/ou acompanhar demandas, logística e empenho orçamentário.</p>
                    </Stack>
                </Section>
                <Section>
                    <EntityNavigation selectedEntity={selectedEntity} onChange={onNavChange} />
                </Section>
                <Section>
                    <DataCRUD 
                        title={'Operadores do Sistema'} 
                        desc={entitiesNavPath} 

                        {...operatorsDataCRUD}

                        getApiParam={{ entityId: selectedEntity }}

                        canAdd={canAdd}
                        canChange={canChange}
                        setApiParam={{ staff_link: selectedEntity }}
                        onSave={onSaveData}
                        onCreate={onCreateData}
                        onFormDisplay={onFormDisplay}
                    /> 
                </Section>
                {userData && <UserLogs userData={userData} /> }
            </Stack>
        </UiShell>
    )
}

export default Operators