import { downloadExport } from './downloadData'

// exports that uses same visual(non tabular)
export const htmlExporterData = (dataExport) => {

    const stylesheets = Array.from(document.styleSheets)
        .map(styleSheet => {
            try {
                return Array.from(styleSheet.cssRules)
                    .map(rule => rule.cssText)
                    .join('\n')
            } catch (e) {
                console.log('Access to stylesheet.cssRules blocked by CORS')
                return ''
            }
        })
        .join('\n')

    const htmlWithStyles = `
        <!DOCTYPE html>
        <html lang="pt-BR">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>CompraMED</title>
                <style>
                    ${stylesheets}
                </style>
            </head>
            <body style="margin: 30px;">
                ${dataExport}
            </body>
        </html>
    `

    //console.log(parseHtmlFormsToJson(exporterRendererRef.current.innerHTML))

    return htmlWithStyles
}

export const htmlExporter = (htmlData) => {
    const htmlDataPage = htmlExporterData(htmlData)
    //const htmlRawData = renderToStaticMarkup(htmlData)
    downloadExport(htmlDataPage, 'exported_data.html')
}
