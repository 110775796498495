import axios from "axios";

// GET: keep simple and return only data or null
export const getOrdersApi = async (data = {}) => {
  let response
  if (data?.entityId)
    response = await axios.get(`/orders/by-entity/${data.entityId}`, { ...data }, { withCredentials: true })
  else
    response = await axios.get('/orders', { ...data }, { withCredentials: true })
  return response.status === 200 ? response.data : null
}

/* export const setOrderItemsApi = async (orderId, items) => {
  return await axios.post(`/orders/items/${orderId}/`, items, {withCredentials: true})
}

export const getOrderItemsApi = async (orderId) => {
  if (orderId === null) return null
  const response = await axios.get(`/orders/items/${orderId}/`, {withCredentials: true})
  return response.status === 200 ? response.data : null
} */

// POST to create if no id property on data
// PATCH to update if id property on data
// return whole request object for custom handling
export const setOrdersApi = async (data) => {
  if (data.hasOwnProperty('id'))
    return await axios.patch(`/orders/${data.id}/`, { ...data }, { headers: {
      'Content-Type': 'multipart/form-data',
    }, withCredentials: true})
  else
    return await axios.post('/orders/', { ...data }, { headers: {
      'Content-Type': 'multipart/form-data',
    }, withCredentials: true})
}

export const getOrdersStats = async () => {
  const response = await axios.get('/orders/stats/', { withCredentials: true })
  return response.status === 200 ? response.data : null
}

// from here we get off changeOrderStatus and create all the flow calls to be controlled by backend.
// so no crud here, this is going to triger states
export const setBuyOrderApi = async (orderId) => {
  return await axios.post(`/orders/buy/${orderId}/`, { withCredentials: true})
}

export const changeOrderStatus = async (orderId, status) => {
  return await axios.patch(`/orders/${orderId}/`, { "status": status }, { withCredentials: true})
}

export const cancelOrderApi = async (orderId, cancelDesc) => {
  return await axios.post(`/orders/cancel/${orderId}/`, { "cancel_desc": cancelDesc }, { withCredentials: true})
}

export const authorizeOrderApi = async (orderId) => {
  return await axios.post(`/orders/authorize/${orderId}/`, { withCredentials: true})
}

export const cloneOrderApi = async (orderId) => {
  return await axios.post(`/orders/clone/${orderId}/`, { withCredentials: true})
}

export const receiveOrderApi = async (orderId) => {
  return await axios.post(`/orders/receive/${orderId}/`, { withCredentials: true})
}

export const cancelOrders = async (orderId, cancelDesc) => {
    return await axios.patch(`/orders/${orderId}/`, { "status": 6, "cancel_desc": cancelDesc }, { withCredentials: true})
}

export const restoreOrders = async (orderId) => {
  return await axios.patch(`/orders/${orderId}/`, { "status": 0 }, { withCredentials: true})
}