import { useState } from "react"
import { 
    Stack, 
    Heading, 
    Section, 
} from '@carbon/react';
import { useAuth } from '../../contexts/AuthContext'
import { UiShell } from '../../containers/Template'
import { DataCRUD } from '../../containers/DataCRUD'
import EntityNavigation from '../../components/EntityNavigation'

// datacrud setup
import { linkedPublicEntitiesDataCRUD } from './linkedPublicEntitiesDataCRUD'

const LinkedPublicEntities = () => {
    const [ selectedEntity, setSelectedEntity ] = useState(null)
    const [ entitiesNavPath, setEntitiesNavPath ] = useState('')
    const { user } = useAuth();
    const canAdd = user.permissions.includes('add_publicentity') && selectedEntity !== null
    const canChange = user.permissions.includes('change_publicentity')

    // DataCRUD callbacks
    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data created: ${result}`)
        }
    }

    const onNavChange = (entityId, place) => {
        setSelectedEntity(entityId)
        setEntitiesNavPath(place)
    }

    return (
        <UiShell>
            <Stack gap={8}>
                <Section>
                    <Heading className="primary">Entidades Vinculadas</Heading>
                    <p>Entidades estão hierarquicamente vinculadas ao ente público, sendo o nível 1 aquele imediato ao ente contratante.</p>
                </Section>
                <Section>
                    <EntityNavigation selectedEntity={selectedEntity} onChange={onNavChange} />
                </Section>
                <Section>
                    <DataCRUD 
                        title={'Entidades'} 
                        desc={entitiesNavPath} 

                        {...linkedPublicEntitiesDataCRUD}

                        getApiParam={{ entityId: selectedEntity }}

                        canAdd={canAdd}
                        canChange={canChange}
                        setApiParam={selectedEntity ? { parent: selectedEntity, platform_admin_rate: 0 } : {}}
                        onSave={onSaveData}
                        onCreate={onCreateData}
                    /> 
                </Section>
            </Stack>
        </UiShell>
    )
}

export default LinkedPublicEntities