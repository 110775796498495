import { useState } from "react";
import { 
    Stack, 
    Heading, 
    Section, 
    Modal,
} from '@carbon/react';
import { useAuth } from '../contexts/AuthContext';
import { UiShell, scrollToTop } from '../containers/Template'
import { DataCRUD } from '../containers/DataCRUD'
import { MediaManager } from '../components/MediaManager'
import { SupplierNavigation } from '../components/SupplierNavigation'

// icons
import { Timer, CheckmarkFilled, CloseFilled, Money, SendAlt, ListNumbered, TaskRemove } from '@carbon/icons-react';

// api imports
import { getToQuoteOrdersApi, getQuoteApi, setQuoteApi, consolidadeQuoteApi, sendQuoteOrderApi, refuseQuoteOrderApi } from '../utils/api/quotes'
import { getParentAndOwnEntitiesApi } from '../utils/api/entities'

// utils
import { maskValue } from '../utils/inputMasks'

// DataManager form setup for Orders
const form = [
    [
        {
            label: 'Código do Pedido',
            helper: '',
            type: 'text',
            key: 'number',
            disabled: true
        },
        {
            label: 'Solicitante',
            helper: '',
            type: 'text',
            key: 'requester_name',
            disabled: true
        },
        {
            label: 'Status',
            helper: '',
            type: 'text',
            key: 'status_code',
            disabled: true
        }
    ],
    [
        {
            label: 'Lançamento',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 0,
            disabled: true
        },
        {
            label: 'Prazo para envio de cotações',
            helper: '',
            type: 'date',
            key: 'quote_deadline'
        },
        {
            label: 'Prazo de entrega',
            helper: '',
            type: 'date',
            key: 'shipping_deadline'
        }
    ],
    [
        {
            label: 'Valor Cotado',
            helper: '',
            type: 'money',
            key: 'my_quote_total',
            context: { status: [2] }
        },
        {
            label: 'Total da Compra',
            helper: '',
            type: 'money',
            key: 'my_quote_ordered',
            context: { status: [3,4,5] }
        },
        {},
        {}
    ],


    [
        {
            label: 'Dados de Envio / Remessa',
            helper: '',
            type: 'header',
            /* context: { status: [3,4,5] }, */
            disabled: true
        },
    ],
    [
        {
            label: 'CNPJ',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_cnpj',
            disabled: true
        },
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_name',
            disabled: true
        },
        {
            label: 'CEP',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_cep',
            disabled: true
        }
    ],
    [
        {
            label: 'Endereço',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_endereco',
            disabled: true
        },
        {
            label: 'Número',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_numero',
            disabled: true
        },
        {
            label: 'Complemento',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_complemento',
            disabled: true
        }
    ],
    [
        {
            label: 'UF',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_uf_code',
            disabled: true
        },
        {
            label: 'Cidade',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_cidade_code',
            disabled: true
        },
        {
            label: 'Gestor',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_manager_name',
            disabled: true
        }
    ],
    /* [
        {
            label: 'Telefone',
            helper: '',
            type: 'text',
            key: 'entity_phone',
            disabled: true
        },
        {
            label: 'E-mail',
            helper: '',
            type: 'text',
            key: 'entity_email',
            disabled: true
        },
        {}
    ], */
    [
        {
            label: 'Dados da Nota Fiscal',
            helper: '',
            type: 'header',
            /* context: { status: [3,4,5] }, */
            disabled: true
        },
    ],
    [
        {
            label: 'CNPJ',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_cnpj',
            disabled: true
        },
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_name',
            disabled: true
        },
        {
            label: 'CEP',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_cep',
            disabled: true
        }
    ],
    [
        {
            label: 'Endereço',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_endereco',
            disabled: true
        },
        {
            label: 'Número',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_numero',
            disabled: true
        },
        {
            label: 'Complemento',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_complemento',
            disabled: true
        }
    ],
    [
        {
            label: 'UF',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_uf_code',
            disabled: true
        },
        {
            label: 'Cidade',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_cidade_code',
            disabled: true
        },
        {
            label: 'Gestor',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_manager_name',
            disabled: true
        }
    ],
    /* [
        {
            label: 'Telefone',
            helper: '',
            type: 'text',
            key: 'parent_phone',
            disabled: true
        },
        {
            label: 'E-mail',
            helper: '',
            type: 'text',
            key: 'parent_email',
            disabled: true
        },
        {}
    ], */

    [
        {
            label: 'Notas do Solicitante',
            helper: '',
            type: 'header',
            disabled: true
        },
    ],
    [
        {
            label: 'Justificativa',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            key: 'justification'
        }
    ],
    [
        {
            label: 'Observações',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            key: 'observation'
        }
    ],
    [
        {
            label: 'Anexos',
            helper: '',
            type: 'multimedia',
            key: 'attachments',
            medias: [
                {
                    label: 'Documentos',
                    helper: 'Tamanho máximo de arquivo é 2Mb. Formatos suportados: Doc, PDF.',
                    key: 'doc'
                },
                {
                    label: 'Fotos e Imagens',
                    helper: 'Tamanho máximo de arquivo é 2Mb. Formatos suportados: JPG e PNG.',
                    key: 'image'
                },
                {
                    label: 'Videos',
                    helper: 'Suporte a links disponibilizados publicamente. Serviços suportados: Youtube, Vimeo, Dailymotion',
                    key: 'url'                    
                }
            ]
        }
    ],
    [
        {
            label: 'Processamento',
            helper: '',
            type: 'header',
            key: 'total',
            disabled: true
        },
    ],
    [
        {
            label: 'Envio do Pedido para Autorização em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 1,
            disabled: true
        },
        {
            label: 'Autorização para Cotação em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 2,
            disabled: true
        },
        {
            label: 'Ordem de Compra gerada em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 3,
            disabled: true
        },
    ],
    [
        {
            label: 'Envio do Pedido em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 4,
            disabled: true
        },
        {
            label: 'Recebimento do Pedido em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 5,
            disabled: true
        },
        {}
        /* {
            label: 'Recebido por',
            helper: '',
            type: 'text',
            key: 'status',
            disabled: true
        }, */
    ],
    [
        {
            label: 'Status for Actions',
            helper: '',
            type: 'hidden',
            key: 'status',
            disabled: true
        },
        {
            label: 'Order Items',
            helper: '',
            type: 'hidden',
            key: 'order_items'
        },
        {

            label: 'Quoted',
            helper: '',
            key: 'is_quoted_by_me',
            disabled: true
        },
        {

            label: 'Quotation',
            helper: '',
            key: 'my_quote',
            disabled: true
        },
        {

            label: 'Quotation Status',
            helper: '',
            key: 'my_quote_status',
            disabled: true
        },
        {
            label: 'Requester ID',
            helper: '',
            key: 'requester',
            disabled: true
        },
        {
            label: 'Entity Fetch',
            helper: '',
            key: '_',
            fetchApi: getParentAndOwnEntitiesApi,
            fetchApiParam: 'requester',
            disabled: true
        },
        {
            label: 'Entity Data',
            helper: '',
            key: 'entity',
            disabled: true
        },
        {
            label: 'Entity Parent Data',
            helper: '',
            key: 'parent',
            disabled: true
        }
    ]
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'Código',
        key: 'number'
    },
    {
        header: 'Solicitante',
        key: 'requester_name'
    },
    {
        header: 'Responsável',
        key: 'responsible_code'
    },
    {
        header: 'Justificativa',
        key: 'justification'
    },
    {
        header: 'Lançamento',
        key: 'created_at'
    },
    {
        header: 'Itens',
        key: 'order_products'
    },
    {
        header: 'Prazo para envio da cotação',
        key: 'quote_deadline'
    },
    {
        header: 'Status',
        key: 'status_code'
    }
]

const dataGridSearchBy = ['number', 'requester', 'responsible', 'justification', 'created_at', 'order_products', 'quote_deadline'] //dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)
const dataGridFilterBy = [
    { 
        label: 'Aguardando Cotação',
        query: [
            {
                key: 'status',
                value: 1
            }
        ]
    },
    { 
        label: 'Cotados',
        query: [
            {
                key: 'status',
                value: 2
            }
        ]
    },
    { 
        label: 'Ordens de Compra',
        query: [
            {
                key: 'status',
                value: 3
            }
        ]
    },
    { 
        label: 'Remessas',
        query: [
            {
                key: 'status',
                value: 4
            }
        ]
    },
    { 
        label: 'Recebidos',
        query: [
            {
                key: 'status',
                value: 5
            }
        ]
    },
    { 
        label: 'Cancelados',
        query: [
            {
                key: 'status',
                value: 6
            }
        ]
    }
]

// grab those actions on onDataAction
const formActions = {
    "authorized": {
        "context": { "status": 1, "is_quoted_by_me": false },
        "label": "Preencher Cotação",
        "icon": Money
    },
    "quoted": {
        "context": { "status": [1,2], "is_quoted_by_me": true },
        "label": "Aguardando Avaliação",
        "icon": Timer,
        "disabled": true
    },
    "viewQuote": {
        "context": { "status": [1,2], "is_quoted_by_me": true },
        "label": "Visualizar Cotação",
        "icon": Money
    },
    "ordered": {
        "context": { "status": 3, "my_quote_status": 3 },
        "label": "Enviar Remessa",
        "icon": SendAlt
    },
    "refuseOrder": {
        "context": { "status": 3 },
        "label": "Desistir",
        "icon": TaskRemove
    },
    "viewItems": {
        "context": { "status": [3,4,5] },
        "label": "Visualizar Itens",
        "icon": ListNumbered
    },
    "canceled": {
        "context": { "status": 6 },
        "label": "Cancelado",
        "icon": CloseFilled,
        "disabled": true
    }
/*     "cancel": {
        "context": { "status": 1 }, 
        "label": "Cancelar Cotação",
        "icon": CloseFilled
    } */
}

// Datagrid for quote items
const quoteItemsGridHeader = [
    {
        header: 'Nome do Produto',
        key: 'item_product_name'
    },
    {
        header: 'Tipo',
        key: 'item_product_product_type_code'
    },
    {
        header: 'Solicitante',
        key: 'item_requester_name'
    },
    {
        header: 'Qtd Solicitada',
        key: 'item_quantity'
    },
    {
        header: 'Apresentação',
        key: 'item_product_presentation'
    },
    {
        header: 'Valor Unitário',
        key: 'price'
    },
    {
        header: 'Valor Total',
        key: 'total'
    }
]

const quoteItemsSearchBy = ['item_product_name', 'item_product_product_type_code', 'item_quantity', 'item_product_presentation', 'price', 'total']

// DataManager form setup
export const quoteForm = [
    [
        {
            label: 'Valor Unitário Proposto',
            helper: 'Itens cotados com valor R$ 0,00 (zero) serão ignorados.',
            type: 'money',
            key: 'price'
        },
        {
            label: 'Nome Comercial',
            helper: '',
            type: 'text',
            key: 'brand_name'
        },
        {
            label: 'EAN13',
            helper: '',
            type: 'text',
            key: 'ean13'
        }
    ],
    [
        {
            label: 'Fabricante',
            helper: '',
            type: 'text',
            key: 'manufacturer'
        },
        {
            label: 'Preço Fábrica (PF) Sem Impostos',
            helper: '',
            type: 'money',
            key: 'factory_price'
        },
        {
            label: 'Embalagem',
            helper: '',
            type: 'text',
            key: 'packaging',
            context: { item_product_product_type: 3 } // product type: 'Medicamento'
        }
    ],
    [
        {
            label: 'Registro',
            helper: '',
            type: 'text',
            key: 'registry',
            context: { item_product_product_type: 3 } // product type: 'Medicamento'
        },
        {
            label: 'Classe Terapêutica CMED',
            helper: '',
            type: 'text',
            key: 'therapeutic_class',
            context: { item_product_product_type: 3 } // product type: 'Medicamento'
        },
        {
            label: 'Forma Terapéutica',
            helper: '',
            type: 'text',
            key: 'therapeutic_form',
            context: { item_product_product_type: 3 } // product type: 'Medicamento'
        }
    ],
    [
        {
            label: 'Nome do Produto',
            helper: '',
            type: 'text',
            key: 'item_product_name',
            disabled: true
        },
        {
            label: 'Apresentação do Produto',
            helper: '',
            type: 'text',
            key: 'item_product_presentation',
            disabled: true
        },
        {
            label: 'Tipo',
            helper: '',
            type: 'text',
            key: 'item_product_product_type_code',
            disabled: true
        }
    ],
    [
        {
            label: 'Substância',
            helper: '',
            type: 'text',
            key: 'item_product_substance',
            disabled: true
        },
        {
            label: 'Concentração',
            helper: '',
            type: 'text',
            key: 'item_product_concentration',
            disabled: true
        },
        {
            label: 'CódigoBR',
            helper: '',
            type: 'text',
            key: 'item_product_code_br',
            disabled: true
        }
    ],
    [
        {
            label: 'Quantidade Solicitada',
            helper: '',
            type: 'text',
            key: 'item_quantity',
            disabled: true
        },
        {},
        {}
    ],
    [
        {
            label: 'Tipo',
            helper: '',
            type: 'hidden',
            key: 'item_product_product_type',
            disabled: true
        }
    ]
]

// grab those actions on onDataAction
const gridActions = {
    "toQuote": {
        "context": { "status": 0 },
        "label": "Consolidar e Enviar Cotação",
        "icon": SendAlt
    },
    "quoted": {
        "context": { "status": [1,2] },
        "label": "Aguardando Avaliação",
        "icon": Timer,
        "disabled": true
    },
    "ordered": {
        "context": { "status": 3 },
        "label": "Enviar Remessa",
        "icon": SendAlt
    },
    "received": {
        "context": { "status": 5 },
        "label": "Remessa Recebida",
        "icon": CheckmarkFilled,
        "disabled": true
    }
}

// move to a strings datafile?
const confirmQuoteMsg = "A cotação selecionada será enviada ao ente público para apreciação pelo gestor. Esta ação não pode ser desfeita. Deseja continuar?"

const confirmSendProductsMsg = "A Ordem de Serviço selecionada será marcada como em trajeto para o seu destino. Antes de enviar, confirme se todos os documentos obrigatórios, como Nota Fiscal, estão anexados. Esta ação não pode ser desfeita. Deseja continuar?"

const confirmRefuseOrderMsg = "Você está prestes a DESISTIR de fornecer os produtos do Pedido selecionado. Ao desistir você concorda em ceder o fornecimento a outros concorrentes. Esta ação não pode ser desfeita.  Deseja mesmo DESISTIR?"

// re-process data from backend to distribute multi order orders into their own tabs
function handleQuoteData(data, filter) {
    if (!data || !Array.isArray(data) || data.length === 0) {
        return []
    }

    data.forEach(order => {
        if (order.status >= 1) {
            order.status = filter?.query?.[0]?.value
            order.status_code = filter.label
        }
        // if supplier had quoted, move to status 2
        /* if (order.is_quoted_by_me) {
            order.status = 2
            order.status_code = "Cotado"
        } */
    })
    
    return data
}

// this screen needs to diferentiate staffs from suppliers, suppliers always use own id to query data, while staffs have a supplier navigation to select and view anyones view
const Quote = () => {
    const [ selectedSupplier, setSelectedSupplier ] = useState(null)
    const [ totalQuote, setTotalQuote ] = useState(0)
    const [ quoteStatus, setQuoteStatus ] = useState(0)
    const [ quoteComplete, setQuoteComplete ] = useState(false)
    const [ fiscalDocs, setFiscalDocs ] = useState({})
    const [ selectedOrder, setSelectedOrder ] = useState(null)
    const [ quoteDataId, setQuoteDataId ] = useState(null)
    const [ viewQuote, setViewQuote ] = useState(false)
    const [ reloadDataCRUD, setReloadDataCRUD ] = useState(0);
    const [ confirmQuoteModal, setConfirmQuoteModal ] = useState(0)
    const [ confirmSentModal, setConfirmSentModal ] = useState(0)
    const [ confirmRefuseorderModal, setConfirmRefuseorderModal ] = useState(0)
    const { user } = useAuth();
    const userSupplierLink = user.supplier_link
    const canChange = user.permissions.includes('change_quotation') && !quoteStatus
    
    // quote DataCRUD callbacks
    const onUpdateQuote = async (data) => {
        // sum all quote items
        const sum = data.reduce((acc, val) => acc + parseFloat(val.total.replaceAll(".","").replaceAll(",",".")), 0)
        setTotalQuote(sum)
        // check if all items were quoted...
        //setQuoteComplete(data.reduce((acc, val) => acc && parseFloat(val.price.replaceAll(".","").replaceAll(",",".")) > 0, true))
        // instead now checks if at least one item is quoted
        setQuoteComplete(data.some(val => parseFloat(val.price.replaceAll(".","").replaceAll(",",".")) > 0))
        // retrieve the whole quote to check status for edit mode
        const quoteData = await getQuoteApi({ orderId: selectedOrder?.id, supplierId: userSupplierLink ?? selectedSupplier })
        setQuoteStatus(quoteData.status)
        setQuoteDataId(quoteData.id)
    }

    // on each form display request we callback with the form data for orders to quote
    const onFormDisplay = async (data, formEditMode) => {
        // no data we hide product items
        if (data === null) {
            //setSaveItems(null)
            setSelectedOrder(null)
            //setSelectedOrderId(null)
            setQuoteDataId(null)
            setFiscalDocs({})
        // if we have order items show then on product list
        } else if (data?.['order_items']) {
            //setSelectedOrder(data.number)
            //setSelectedOrderId(data.id)
            setSelectedOrder(data)
            setQuoteDataId(data?.my_quote)
        } else {
            setSelectedOrder(null)
            //setSelectedOrder(data)
            //setSelectedOrderId(null)
            setQuoteDataId(data?.my_quote)
            setFiscalDocs({})
        }
    }
    
    // Actions callback: based on formActions
    const onDataAction = (action, data) => {
        //setOrderDataId(dataId)
        switch (action) {
            case "authorized": 
            case "viewQuote": 
            case "viewItems": 
                //setQuoteDataId(data?.my_quote)
                // retrieve quote data via quote, using order id and supplier id
                setViewQuote(true)
                // scroll screen to the top
                scrollToTop()
            break
            case "refuseOrder":
                setQuoteDataId(data.my_quote)
                setConfirmRefuseorderModal(1)
            break
            case "toQuote": 
                //setSelectedOrderId(data.id)
                // open modal and ask if user are sure about the action
                setConfirmQuoteModal(1)
            break
            case "ordered": 
                //"context": { "status": 3 },
                //"label": "Aguardando Envio",
                // call modal asking for confirmation on sent products
                // reset files
                setFiscalDocs({})
                // resolve on onConfirmSendAction
                setConfirmSentModal(1)
            break
            case "shipping": 
                //"context": { "status": 4 },
                //"label": "Marcar Pedido como Recebido",
                /* changeOrderStatus(dataId, 5) */
            break
            case "received": // disabled, never reachs here
                //"context": { "status": 5 },
                //"label": "Recebido",
            break
            case "canceled": // disabled, never reachs here
                //"context": { "status": 6 },
                //"label": "Cancelado",
            break;
            default:

            break
        }
    }

    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
            scrollToTop()
        }
    }

    const onNavChange = (supplierId) => {
        setSelectedSupplier(supplierId)
        setReloadDataCRUD(reloadDataCRUD+1)
    }

    const onConfirmQuoteAction = async (e) => {
        // change quote status to consolidated
        consolidadeQuoteApi(quoteDataId)
        // close quote view
        setViewQuote(false)
        setConfirmQuoteModal(0)
        setReloadDataCRUD(reloadDataCRUD+1)
    }

    const onConfirmSendAction = async (e) => {
        //if (Object.keys(fiscalDocs).length > 0) {
            // mark order as sent
            sendQuoteOrderApi(quoteDataId, fiscalDocs)
            setConfirmSentModal(0)
            setReloadDataCRUD(reloadDataCRUD+1)
            // get back to main view
            setViewQuote(false)
        //} else {
            // throws error
        //}
    }

    const onConfirmRefuseOrderAction = async (e) => {
        // mark order as refused
        await refuseQuoteOrderApi(quoteDataId)
        setConfirmRefuseorderModal(0)
        setReloadDataCRUD(reloadDataCRUD+1)
    }

    //
    // multimedia handling via attachements key
    //
    const onMediaDelete = (attachmentName) => {
        // check if attachmentId exists in any fiscalDocs['attachments'] object as parameter, if so, remove it! and update
        const updatedAttachments = fiscalDocs.attachments.filter(
            (attachment) => attachment.doc.name !== attachmentName
        )

        setFiscalDocs({
            ...fiscalDocs,
            attachments: updatedAttachments
        }) 
    }

    const handleChangeFile = (mediaFileObjects) => {
        setFiscalDocs({
            ...fiscalDocs,
            attachments: [...(fiscalDocs.attachments || []), ...(mediaFileObjects || [])]
        }) 
    }

    return (
        <UiShell>
            <Stack gap={8}>
                {confirmQuoteModal !== 0 &&
                    <Modal 
                        open={confirmQuoteModal}
                        modalHeading="Consolidar e Enviar Cotação"
                        primaryButtonText="Enviar Cotação" 
                        secondaryButtonText="Cancelar" 
                        onRequestSubmit={onConfirmQuoteAction} 
                        onRequestClose={() => setConfirmQuoteModal(0)}
                    >
                        <p>{confirmQuoteMsg}</p>
                    </Modal>
                }
                {confirmSentModal !== 0 &&
                    <Modal 
                        open={confirmSentModal}
                        /* primaryButtonDisabled={Object.keys(fiscalDocs).length === 0} */
                        modalHeading="Enviar Remessa"
                        /* modalLabel="Enviar Remessa"  */
                        primaryButtonText="Enviar Remessa" 
                        secondaryButtonText="Cancelar" 
                        onRequestSubmit={onConfirmSendAction} 
                        onRequestClose={() => setConfirmSentModal(0)}
                    >                  
                        <p>{confirmSendProductsMsg}</p>
                        <br/>
                        <MediaManager
                            label={"Documentos Fiscais"}
                            helper={"Tamanho máximo de arquivo é 2Mb. Formatos suportados: Doc, PDF."}
                            type={'doc'}
                            buttonKind="tertiary"
                            disabled={fiscalDocs?.attachments?.length === 3}
                            onAdd={handleChangeFile}
                            onDelete={onMediaDelete}
                            medias={fiscalDocs.attachments}
                            readMode={false}
                        />    
                </Modal>
                }
                {confirmRefuseorderModal !== 0 &&
                    <Modal 
                        open={confirmRefuseorderModal}
                        modalHeading="Desistência de Fornecimento"
                        primaryButtonText="Sim" 
                        secondaryButtonText="Não" 
                        onRequestSubmit={onConfirmRefuseOrderAction} 
                        onRequestClose={() => setConfirmRefuseorderModal(0)}
                    >                  
                        <p>{confirmRefuseOrderMsg}</p>
                </Modal>
                }
                {viewQuote === false && (
                    <>
                        <Section>
                            <Heading className="primary">Cotações</Heading>
                            <p>Visualize cotações recebidas e envie propostas para avaliação.</p>
                        </Section>
                        {!userSupplierLink &&
                            <Section>
                                <SupplierNavigation 
                                    selectedSupplier={selectedSupplier}
                                    onChange={onNavChange}
                                />
                            </Section>
                        }
                        <Section>
                            <DataCRUD 
                                title={'Cotações'} 
                                desc={'Todos os Cadastros'} 
                                headers={dataGridHeader} 
                                searchBy={dataGridSearchBy}
                                filters={dataGridFilterBy}
                                getApi={getToQuoteOrdersApi}
                                getApiParam={{ supplierId: userSupplierLink ?? selectedSupplier }}
                                getApiProcessData={handleQuoteData}

                                onFormDisplay={onFormDisplay}
                                canCallActions={!(userSupplierLink === null && selectedSupplier === null)}
                                canAdd={false}
                                canChange={false}
                                permissions={user.permissions}

                                form={form}
                                formTitleKey={'number'}
                                formActions={formActions}
                                onAction={onDataAction}
                                forceReload={reloadDataCRUD}
                            /> 
                        </Section>
                    </>
                )}
                {viewQuote === true &&
                    <>
                        <Section>
                            <Stack gap={5}>
                                <Heading className="primary">{`Pedido ${selectedOrder?.number}`}</Heading>
                                <p>Cotação</p>
                                <div><a href="#" onClick={() => setViewQuote(false)}>Voltar para Lista</a> / {`Pedido ${selectedOrder?.number}`}</div>
                                { selectedOrder?.status <= 2 && 
                                    <p>Itens cotados com Valor Unitário 0,00 (zero) serão ignorados e a cotação enviada será parcial, desconsiderando o item.</p>
                                }
                            </Stack>
                        </Section>
                        <Section>
                            <DataCRUD 
                                title={`Pedido ${selectedOrder?.number}`} 
                                desc={`Valor Total: ${maskValue(totalQuote.toString().replaceAll(","," ").replaceAll(".",",").replaceAll(" ", "."), 'money')}`} 
                                headers={quoteItemsGridHeader} 
                                getApi={getQuoteApi}
                                getApiParam={{ orderId: selectedOrder?.id, supplierId: userSupplierLink ?? selectedSupplier }}
                                getApiKey={selectedOrder?.status <= 2 ? 'quote_items' : 'winner_quote_items'}
                                getApiFlatData={true}
                                canChange={canChange}
                                canCallActions={quoteComplete}
                                canAdd={false}
                                onUpdateDataGrid={onUpdateQuote}
                                gridActions={gridActions}
                                onGridAction={onDataAction}
                                permissions={user.permissions}
                                
                                formTitleKey={'item_product_name'}
                                form={quoteForm}
                                setApi={setQuoteApi}
                                onSave={onSaveData}
                                formActions={formActions}
                                onAction={onDataAction}
                                forceReload={reloadDataCRUD}
                                localGridData={true}
                                searchBy={quoteItemsSearchBy}
                            /> 
                        </Section>
                    </>
                }
            </Stack>
        </UiShell>
    )
}

export default Quote