import { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { 
  Button, 
  TextInput, 
  Form, 
  Stack, 
  Heading, 
  Section, 
  FlexGrid, 
  Row, 
  Column
} from '@carbon/react';
import Logo from '../components/Logo'
import LoginBox from '../containers/LoginBox'
import ResetPassword from '../containers/ResetPassword'

import { clearToken } from '../utils/api/auth'

const backgroundImage = `${process.env.PUBLIC_URL}/login_photo.jpg`;

const LoginPhoto = () => {

  const photoStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%', 
  };

  return <div style={photoStyle}></div>;
};


const Login = () => {
  const [ searchParams ] = useSearchParams();
  const resetToken = searchParams.get('reset');

  // clear tokens in case
  useEffect(() => {
    clearToken()
  }, [])

  return(
    <FlexGrid style={{backgroundColor: '#0F62FE', display: 'grid', alignItems: 'center', minHeight: '100vh', maxInlineSize: 'none' }}>
      <Row style={{ height: '560px' }} condensed>
        <Column sm={2} md={{ span: 3, offset: 1 }} lg={{ span: 6, offset: 2 }} style={{ backgroundColor: '#ffffff', padding: '48px 36px 48px 36px' }}>
          <Section level={4}>
            <Stack gap={5}>
              <Section style={{ textAlign: 'center' }}><Logo /></Section>
              {resetToken ? <ResetPassword token={resetToken} /> : <LoginBox />}
              <Section style={{ marginTop: '20px' }}>
                <p>CompraMED - Todos os Direitos Reservados.</p>
              </Section>
            </Stack>
          </Section>
        </Column>
        <Column sm={2} md={3} lg={6}>
          <LoginPhoto />
        </Column>
      </Row>
    </FlexGrid>
  )
}

export default Login