import { useState, useEffect } from "react";
import { 
    Stack, 
    Heading, 
    Section, 
    Tile,
    FlexGrid,
    Row,
    Column,
    Modal,
    TextArea,
} from '@carbon/react';
import { useAuth } from '../../contexts/AuthContext';
import { UiShell } from '../../containers/Template'
import { DataCRUD } from '../../containers/DataCRUD'
import { balanceContingencyDataCRUD } from './balanceContingencyDataCRUD'

// api imports
import { getBalanceStats, cancelBalance, restoreBalance } from '../../utils/api/balance'

// utils
import { maskValue } from '../../utils/inputMasks'

// move to a strings datafile?
const cancelConfirmMsg = "O item selecionado será removido do cadastro. Os dados nele contidos serão mantidos no sistema para fins de relatório. Deseja realmente remover?"
const restoreConfirmMsg = "O item selecionado será readicionado ao cadastro. Deseja continuar?"

const BalanceContingency = () => {
    const [ stats, setStats ] = useState({})
    const [ cancelNoteModal, setCancelNoteModal ] = useState(0)
    const [ balanceDataId, setBalanceDataId ] = useState("")
    const [ cancelDesc, setCancelDesc ] = useState("")
    const [reloadDataCRUD, setReloadDataCRUD] = useState(0);
    const { user } = useAuth();
    const canAdd = user.permissions.includes('add_balance')
    const canChange = user.permissions.includes('change_balance')
    const canCallActions = user.permissions.includes('change_balance')
    
    // DataCRUD callbacks
    // Actions callback: based on formActions
    const onDataAction = (action, dataId) => {
        setBalanceDataId(dataId)
        switch (action) {
            case "cancel":
                setCancelNoteModal(1)
            break
            case "restore":
                setCancelNoteModal(2)
            break
            default:

            break
        }
    }

    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
            // reload balance stats
            fetchBalanceStats()
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data created: ${result}`)
            // reload balance stats
            fetchBalanceStats()
        }
    }

    // called by modal
    const onCancelRestoreAction = async (e) => {
        if (cancelNoteModal === 1) {
            await cancelBalance(balanceDataId, cancelDesc)
        } else if (cancelNoteModal === 2) {
            await restoreBalance(balanceDataId)
        }
        setCancelNoteModal(0)
        setReloadDataCRUD(reloadDataCRUD+1)
        // reload balance stats
        fetchBalanceStats()
    }

    const fetchBalanceStats = async () => {
        const data = await getBalanceStats()
        setStats(data)
    }

    useEffect(() => {
        fetchBalanceStats()
    }, [])

    return (
        <UiShell>
            <Stack gap={8}>
                <Modal 
                    open={cancelNoteModal >= 1 ? true : false}
                    danger 
                    modalHeading={cancelNoteModal === 1 ? cancelConfirmMsg : restoreConfirmMsg}
                    modalLabel="Nota de Empenho" 
                    primaryButtonText="Sim" 
                    secondaryButtonText=" Não" 
                    onRequestSubmit={onCancelRestoreAction} 
                    /* loadingStatus={status}  */
                    loadingDescription={"description"} 
                    onLoadingSuccess={() => console.log("sucess")}
                    onRequestClose={() => setCancelNoteModal(0)}
                >
                    {cancelNoteModal === 1 &&
                        <TextArea
                            invalidText={''}
                            invalid={false} 
                            onChange={(e) => setCancelDesc(e.target.value)} 
                            disabled={false} 
                            value={cancelDesc} 
                            id={'cancelRestoreInput'} 
                            name={'cancelRestoreInput'} 
                            labelText={'Motivo do Cancelamento'} 
                            helperText={'Limite de 500 caracteres'} 
                        />  
                    } 
                </Modal>

                <Section>
                    <Heading className="primary">Empenho Orçamentário</Heading>
                    <p>Alocação de recursos para aquisição de produtos.</p>
                </Section>
                <Section level={4}>
                    <FlexGrid style={{ paddingInline: '0px' }}>
                        <Row>
                            <Column>
                                <Tile>
                                    <Heading>{maskValue(stats?.total, 'money')}</Heading>
                                    Valor Total de Empenho
                                </Tile>
                            </Column>
                            <Column>
                                <Tile>
                                    <Heading>{maskValue(stats?.withdraw, 'money')}</Heading>
                                    Valor Total de Destinado
                                </Tile>
                            </Column>
                            <Column>
                                <Tile>
                                    <Heading>{maskValue(stats?.balance, 'money')}</Heading>
                                    Saldo
                                </Tile>
                            </Column>
                        </Row>
                        <Row style={{ marginTop: '1.5rem' }}>
                            <Column>
                                <Tile>
                                    <Heading>{maskValue(stats?.canceled, 'money')}</Heading>
                                    Cancelado
                                </Tile>
                            </Column>
                            <Column></Column>
                            <Column></Column>
                        </Row>
                    </FlexGrid>
                </Section>
                <Section>
                    <DataCRUD 
                        title={'Empenhos'} 
                        desc={'Todos os cadastros'} 

                        {...balanceContingencyDataCRUD}

                        canAdd={canAdd}
                        canChange={canChange}
                        canCallActions={canCallActions}
                        onSave={onSaveData}
                        onCreate={onCreateData}
                        onAction={onDataAction}
                        forceReload={reloadDataCRUD}
                    /> 
                </Section>
            </Stack>
        </UiShell>
    )
}

export default BalanceContingency