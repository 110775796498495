//import button from 'react-bootstrap/button';

const logo = `${process.env.PUBLIC_URL}/logo.svg`;
const smallStyle = { width: '120px' }

function Logo({ small }) {
  return (
    <img src={logo} style={small ? smallStyle : undefined} />
  );
}

export default Logo;