import { 
    Stack, 
    Heading, 
    Section, 
} from '@carbon/react';
import { useAuth } from '../../contexts/AuthContext';
import { UiShell } from '../../containers/Template'
import { DataCRUD } from '../../containers/DataCRUD'

// datacrud setup
import { productsDataCRUD } from './productsDataCRUD'

const Products = () => {
    const { user } = useAuth();
    const canAdd = user.permissions.includes('add_product')
    const canChange = user.permissions.includes('change_product')
    
    // DataCRUD callbacks
    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data created: ${result}`)
        }
    }

    return (
        <UiShell>
            <Stack gap={8}>
                <Section>
                    <Heading className="primary">Produtos</Heading>
                    <p>A lista de produtos define e delimita o que entidades vinculadas podem requerer através dos pedidos de cotação.</p>
                </Section>
                <Section>
                    <DataCRUD 
                        title={'Produtos'} 
                        desc={'Todos os cadastros'} 

                        {...productsDataCRUD}

                        canAdd={canAdd}
                        canChange={canChange}
                        onSave={onSaveData}
                        onCreate={onCreateData}
                    /> 
                </Section>
            </Stack>
        </UiShell>
    )
}

export default Products