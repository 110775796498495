import { 
    SideNavDivider, 
    SideNavItems, 
    SideNavLink, 
    Heading,
    Section,
} from '@carbon/react'
import { 
    Home, 
    Logout, 
    User, 
    BuildingInsights_1, 
    ShoppingCartPlus, 
    Wallet, 
    Pills, 
    ShoppingBag, 
    HelpDesk, 
    Report, 
    Unknown 
} from '@carbon/icons-react'
import { useNavigate } from 'react-router-dom'

const generalMenu = [
    { 
        title: 'Entes Principais',
        path: '/entities',
        icon: BuildingInsights_1,
        access: 'view_publicentity'
    },
    { 
        title: 'Entidades Vinculadas',
        path: '/linked-entities',
        icon: BuildingInsights_1,
        access: 'view_publicentity'
    },
    { 
        title: 'Operadores Autorizados',
        path: '/operators',
        icon: User,
        access: 'view_entity_user'
    },
    { 
        title: 'Fornecedores',
        path: '/suppliers',
        icon: BuildingInsights_1,
        access: 'view_supplier'
    },
    { 
        title: 'Representantes Autorizados',
        path: '/supplier-operators',
        icon: User,
        access: 'view_supplier_user'
    },
    { 
        title: 'Produtos',
        path: '/products',
        icon: Pills,
        access: 'view_product'
    },
    { 
        title: 'Empenho Orçamentário',
        path: '/balance',
        icon: Wallet,
        access: 'view_balance'
    },
    { 
        title: 'Solicitações de Compra',
        path: '/orders',
        icon: ShoppingCartPlus,
        access: 'view_order'
    },
    { 
        title: 'Cotações',
        path: '/quotes',
        icon: ShoppingBag,
        access: 'view_quotation'
    },
    { 
        title: 'Suporte',
        path: '/support',
        icon: HelpDesk,
        access: 'view_ticket'
    },
    { 
        title: 'Relatórios',
        path: '/reports',
        icon: Report,
        access: 'view_reportexportlog'
    }/* ,
    { 
        title: 'Manual do Usuário',
        path: '/manual',
        icon: Unknown
    } */
]

const NavigationMenu = ({ logoutUser, user }) => {
    const navigate = useNavigate()
    const userMenu = user.is_staff ? generalMenu : generalMenu.filter(e => user.permissions.includes(e.access))
    return (
        <SideNavItems>
            <Section>
                <SideNavLink renderIcon={Home} onClick={() => navigate("/")}>
                    Inicio
                </SideNavLink>
                <SideNavLink renderIcon={Logout} onClick={() => logoutUser()}>
                    Sair
                </SideNavLink>
            </Section>
            <SideNavDivider />
            <Section level={6}>
                <Heading style={{ margin: '1rem 0 0 1rem' }}>{user.group_name}</Heading>
                {userMenu.map((el, idx) => 
                    <SideNavLink key={idx} renderIcon={el.icon} onClick={() => navigate(el.path)}>
                        {el.title}
                    </SideNavLink>
                )}
            </Section>
        </SideNavItems>
    )
}

export default NavigationMenu