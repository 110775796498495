// api imports
import { getEntitiesApi, setEntitiesApi } from '../../utils/api/entities'
import { getStatesApi, getCitiesApi, getAddressByCep } from '../../utils/api/location'
import { getAccessProfilesApi } from '../../utils/api/auth'
//import { getUsersApi } from '../../utils/api/accounts'

// DataManager form setup
const form = [
    [
        {
            label: 'CNPJ',
            helper: '',
            type: 'cnpj',
            key: 'cnpj'
        },
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            key: 'name'
        },
        {
            label: 'Subordinada a(o)',
            helper: '',
            type: 'text',
            key: 'parent',
            disabled: true
        }
    ],
    [
        {
            label: 'CEP',
            helper: '',
            type: 'cep',
            key: 'cep'
        },
        {
            label: 'Endereço',
            helper: '',
            type: 'text',
            key: 'endereco'
        },
        {
            label: 'Número',
            helper: '',
            type: 'text',
            key: 'numero'
        }
    ],
    [
        {
            label: 'Complemento',
            helper: '',
            type: 'text',
            key: 'complemento'
        },
        {
            label: 'UF',
            helper: '',
            type: 'select',
            fetchApi: getStatesApi,
            optionText: 'abbreviation',
            key: 'uf'
        },
        {
            label: 'Cidade',
            helper: '',
            type: 'select',
            fetchApi: getCitiesApi,
            fetchApiParam: 'uf', // use param from key reference...
            optionText: 'name',
            key: 'cidade'
        }
    ],
    [
        {
            label: 'Contrato Número',
            helper: '',
            type: 'text',
            key: 'contract_number'
        },
        {
            label: 'Saldo Orçamentário',
            helper: 'Saldo Remanescente do Contrato',
            type: 'money',
            key: 'contract_income',
            disabled: true
        },
        {
            label: 'Despesa Autorizada',
            helper: 'Valor Total Despendido do Contrato',
            type: 'money',
            key: 'contract_outcome',
            disabled: true
        }
    ],
    [
/*         {
            label: 'Gestor',
            helper: '',
            type: 'select',
            fetchApi: getUsersApi,
            optionText: 'name',
            key: 'manager_account'
        }, */
        {
            label: 'Gestor',
            helper: '',
            type: 'text',
            key: 'manager_name',
            disabled: true
        },
        {
            label: 'Firmado em',
            helper: '',
            type: 'date',
            key: 'contract_start_date'
        },
        {
            label: 'Contrato válido até:',
            helper: 'Dados serão congelados a partir desta data.',
            type: 'date',
            key: 'contract_end_date'
        }
    ],
    [
        {
            label: 'Taxa Administrativa (%)',
            helper: '',
            type: 'percentage',
            key: 'platform_admin_rate'
        },
        {
            label: 'Status do Contrato',
            helper: '',
            type: 'text',
            key: 'contract_status_code',
            disabled: true
        },
        {
            label: 'Telefone',
            helper: '',
            type: 'phone',
            key: 'phone'
        }
    ],
    [
        {
            label: 'E-mail',
            helper: '',
            type: 'text',
            key: 'email'
        },
        {
            label: 'Permitir Acesso ao Sistema:',
            type: 'select',
            fetchApi: getAccessProfilesApi,
            optionText: 'name',
            key: 'access_time_config'
        },
        {}
        
        /* ,        
        {
            label: 'Desconectar Usuários Ausentes por mais de:',
            helper: '',
            key: 'manager_account',
            disabled: true
        } */
    ]
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'CNPJ',
        key: 'cnpj'
    },
    {
        header: 'Nome',
        key: 'name'
    },
    {
        header: 'UF',
        key: 'uf_code'
    },
    {
        header: 'Contrato Número',
        key: 'contract_number'
    },
    {
        header: 'Status',
        key: 'contract_status_code'
    },
    {
        header: 'Firmado em',
        key: 'contract_start_date'
    }
]

const dataGridSearchBy = dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)

const dataGridFilterBy = [
    { 
        label: 'Todos',
        query: null
    },
    { 
        label: 'Ativos',
        query: [
            {
                key: 'contract_status',
                value: 1
            }
        ]
    },
    { 
        label: 'Inativos',
        query: [
            {
                key: 'contract_status',
                value: 0
            }
        ]
    }
]


// the main DataCRUD setup
export const publicEntitiesDataCRUD = {
    headers: dataGridHeader, 
    searchBy: dataGridSearchBy,
    filters: dataGridFilterBy,
    form: form,
    formTitleKey: 'name',
    getApi: getEntitiesApi,
    setApi: setEntitiesApi
}
